<template>
    <BannerTemplate :bannerSource="bannerSource" :title="`Welkom bij Roadpursuit`" :intro="''">
        <LoadingIcon :isLoading="isValidating"/>
        <div class="px-2" v-if="!isValidating">
            <v-container class="pt-0">
                <v-row class="pt-0">
                    <v-col cols="12" class="py-0">
                        <p>Beste {{firstname}},</p>
                    </v-col>
                </v-row>
                <v-row class="pt-0" v-if="isValid">
                    <v-col cols="12" class="py-0">
                    <p v-html="greetings"></p>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-btn color="primary" block @click="goToMyDashboard()" >
                            Naar Mijn Roadpursuit
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row class="pt-0" v-if="!isValid">
                    <v-col cols="12" class="py-0">
                    <div>
                        Deze activatiecode is ongeldig. Ondervindt u problemen? Neem contact op met
                        <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <a
                                style="color:#76baff"
                                target="_blank"
                                href="mailto:service@roadpursuit.com"
                                @click.stop
                                v-on="on">
                                service@roadpursuit.com
                            </a>
                            </template>
                            Opent uw mail app
                        </v-tooltip>
                        .
                    </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </BannerTemplate>
</template>

<script>
import BannerTemplate from '@/components/common/BannerTemplate';
import LoadingIcon from '@/components/common/LoadingIcon';
import RouteMixin from "@/mixins/route.mixin";
import MetaTagsMixin from "@/mixins/metatags.mixin";

export default {
    name: 'RegistratieWelkom',
    components: 
    {
        BannerTemplate,
        LoadingIcon
    },
    mixins: [RouteMixin, MetaTagsMixin],
    mounted () 
    {
        this.$store.commit('setFooterVisibility', true);
        const activationString = this.$route.params.activationString;
        this.$store.dispatch('accountModule/getFirstNameFromActivationString', activationString)
        .then(firstname => {
            this.firstname = firstname;

            this.$store.dispatch('accountModule/activateAccount', activationString)
            .then(data => {
                this.isValid = data;
                this.isValidating = false;
            })
            .catch(() => {
                this.isValid = false;
                this.isValidating = false;
            })
        })
    },
    data: () => ({
        bannerSource: require('@/assets/album/My Roadpursuit/my-roadpursuit-02.jpg'),
        isValid: false,
        isValidating: true,
        firstname: "",
        greetings: "<p>Welkom bij de Roadpursuit familie! Uw account is succesvol geactiveerd.</p><p>Via onderstaande knop gaat u naar uw persoonlijke Roadpursuit Dashboard. Hier kunt u onder andere zien met welke roadtrips u heeft meegereden, maar ook het aantal gereden kilometers per voertuig, geplaatste reviews, facturen en uiteraard foto's van uw auto's!</p>"
    }),
    metaInfo() {
        return {
        title: '| Welkom',
        meta: [
            this.getKeywords(`Registreren, registratie, Mijn Roadpursuit`),
            this.getMetaDescription(`Welkom bij Roadpursuit`),
            this.getAuthor(),
            ...this.getSocialMediaMetas(`Welkom bij Roadpursuit`, `Welkom bij Roadpursuit`, `${window.location.origin}${this.bannerSource}`, 'Welkom bij Roadpursuit'),
        ]
        };
    },
}
</script>
